// COGNITO ID TOKEN (json) KEYS 
export const COGNITO_ID_USER_GROUP = "cognito:groups";
export const COGNITO_ID_AUD = "aud";
export const COGNITO_ID_AUTH_TIME = "auth_time";
export const COGNITO_ID_USER_NAME = "cognito:username";
export const COGNITO_ID_EMAIL = "email";
export const COGNITO_ID_SUB = "sub";

// COGNITO UserData Attributes Name
export const COGNITO_ATTR_NAME = "name";
export const COGNITO_ATTR_EMAIL = "email";
export const COGNITO_ATTR_SUB = "sub";
export const COGNITO_ATTR_COMPANY = "custom:company";
export const COGNITO_ATTR_LANGUAGE = "custom:language";
